/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from './actions';

export default (state, action) => {
	const result = action.res;
	switch (action.type) {
		case ACTIONS.GET_APP_Name:
			return {
				...state,
				org: result.data.org,
			};

		default:
			return state;
	}
};
