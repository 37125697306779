import API from '../../../constants/api';
import axios from 'axios';

export function getAppNameFromID() {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API.USERS}/getAppName`)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
}
