const server_url = process.env.REACT_APP_API_SERVER;
const cms_server_url = process.env.REACT_APP_CMS_API_SERVER;
const registry_server_url = process.env.REACT_APP_REGISTRY_SERVER;

const base_url = server_url + '/api/v1';
const cms_base_url = cms_server_url + '/api/v1';
const registry_url = registry_server_url + '/api/v1';
module.exports = {
	AUTH: base_url + '/auth',
	DISTRIBUTORS: base_url + '/distributors',
	MEMBERS: base_url + '/members',
	MEMBERS_FROM_REGISTRY: registry_url + '/farmer',
	TRACKINGS: registry_url + '/trackings',
	USERS: base_url + '/users',
	SETTINGS: base_url + '/settings',
	ROLES: base_url + '/roles',
	FARMS: base_url + '/farms',
	REPORTS: base_url + '/report',
	PRODUCTS: base_url + '/products',
	BATCHES: base_url + '/batches',
	VARIETY: base_url + '/variety',
	CMSPRODUCTS: cms_base_url + '/products',
	ACTIVITY: cms_base_url + '/activity',
	PRODUCT_ACTIVITY: base_url + '/activity',
	IMPORT_ACTIVITY: base_url + '/import_activity',
	FARMER_ACTIVITY: base_url + '/farmer_activity',
	ENTERPRISE_ID: registry_url + '/registry',
	UPLOAD: base_url + '/upload',
};
